<template>
  <lf-dropdown
    ref="dropdown"
    class="w-full"
    :value="value || undefined"
    :placeholder="placeholder"
    :options="dropdownOptions"
    :is-loading="isLoadingMore"
    :name="name"
    :full-width="fullWidth"
    :show-clear-filter="showClearFilter"
    :as-badge="asBadge"
    :teleport-options="teleportOptions"
    :search-placeholder="searchTerm"
    :warning-as-error="warningAsError"
    :drop-up="dropUp"
    :disabled="disabled"
    :sort="sort"
    search-enabled
    :show-warning-icon="showWarningIcon"
    @change="handleOptionChange"
    @toggle="handleDropdownToggled"
    @search:change="handleSearchChange"
  >
    <template v-slot:label="{ option, isSelected, optionId }">
      <slot
        name="option"
        :option="option"
        :options="paginatedData"
        :is-selected="isSelected"
        :option-id="optionId"
      />
    </template>
    <template
      v-if="
        getPaginatedOptions &&
        paginatedOptionsMeta.current_page !==
          Number(paginatedOptionsMeta.last_page)
      "
      #action
    >
      <a
        class="text-primary text-sm cursor-pointer font-medium transform active:scale-95"
        @click.stop="loadMore()"
      >
        {{ $t("COMMON.LOAD_MORE") }}
      </a>
    </template>
  </lf-dropdown>
</template>

<script lang="ts">
import { ref, computed } from "vue";
import type { IPaginatedResponse, PaginatedOptionsMeta } from "@/models/common";
import get from "lodash/get";

type LfDropdownPropTypes = InstanceType<typeof LfDropdown>["$props"];

interface Props {
  teleportOptions?: LfDropdownPropTypes["teleportOptions"];
  asBadge?: LfDropdownPropTypes["asBadge"];
  fullWidth?: LfDropdownPropTypes["fullWidth"];
  showClearFilter?: LfDropdownPropTypes["showClearFilter"];
  warningAsError?: LfDropdownPropTypes["warningAsError"];
  value?: string | number | boolean;
  placeholder?: string;
  accessor: string;
  name?: string;
  searchTerm?: string;
  includeNone?: boolean;
  noneOptionLabel?: string;
  identifier?: string;
  showWarningIcon?: boolean;
  dropUp?: boolean;
  disabled?: boolean;
  sort?: boolean;
  // Disabling no-any for time being
  // TODO: Use component generics once available in Vue 3.3.0
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getPaginatedOptions?: (...params: any[]) => any;
}
</script>

<script setup lang="ts">
import LfDropdown from "@/components/ui/inputs/LfDropdown.vue";

const emit = defineEmits<{
  // TODO use component generics once available
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (e: "change", val: any): void;
  (e: "loading", loading: boolean): void;
}>();

const props = withDefaults(defineProps<Props>(), {
  value: "",
  placeholder: "",
  name: "",
  searchTerm: "",
  fullWidth: false,
  showClearFilter: false,
  asBadge: false,
  includeNone: false,
  identifier: "id",
  warningAsError: false,
  showWarningIcon: true,
  dropUp: false,
  disabled: false,
  sort: false
});

const dropdown = ref<InstanceType<typeof LfDropdown> | null>(null);
const search = ref("");
const isLoadingMore = ref(false);
const paginatedData = ref<unknown[]>([]);
const paginatedOptionsMeta = ref<PaginatedOptionsMeta>({
  current_page: 0,
  last_page: null
});

const dropdownOptions = computed(() => {
  let options = paginatedData.value.reduce<Record<string, string>>(
    (acc, item) => {
      const typedItem = item as Record<string, unknown>;
      const id = typedItem[props.identifier] as string;
      const value = get(typedItem, props.accessor) as string;
      acc[id] = value;
      return acc;
    },
    {}
  );

  if (props.includeNone) {
    options = { 0: props.noneOptionLabel ?? "None", ...options };
  }

  return options;
});

const resetOptions = () => {
  paginatedData.value = [];
  paginatedOptionsMeta.value = {
    current_page: 0,
    last_page: null
  };
};

const handleSearchChange = (value: string, load = true) => {
  if (value === search.value) {
    return;
  }
  search.value = value;
  if (dropdown.value) {
    dropdown.value.search = value;
  }
  resetOptions();
  if (load) {
    loadMore();
  }
};

const handleOptionChange = (val: string | number) => {
  const selectedValue = paginatedData.value.find(
    (item) =>
      (item as Record<string, unknown>)[props.identifier]?.toString() ===
      val.toString()
  );
  emit("change", selectedValue);
  handleSearchChange("", false);
};

const handleDropdownToggled = (value: boolean) => {
  if (!paginatedOptionsMeta.value.current_page && value) {
    loadMore();
  }
};

const loadMore = async (params: Record<string, string | string[]> = {}) => {
  if (!props.getPaginatedOptions) {
    return;
  }
  const { current_page, last_page } = paginatedOptionsMeta.value;
  if (current_page === last_page) {
    return;
  }
  try {
    isLoadingMore.value = true;
    emit("loading", true);

    const pageToGet = current_page + 1;
    const response: IPaginatedResponse<unknown> =
      await props.getPaginatedOptions({
        ...params,
        page: pageToGet,
        search: search.value || null
      });
    paginatedData.value.push(...response.data);
    paginatedOptionsMeta.value.current_page = response.meta.current_page;
    paginatedOptionsMeta.value.last_page = response.meta.last_page;

    emit("loading", false);
    return response.data;
  } catch {
    return [];
  } finally {
    isLoadingMore.value = false;
  }
};

const resetPaginatedOptionsMeta = () => {
  paginatedOptionsMeta.value = {
    current_page: 0,
    last_page: null
  };
};

defineExpose({
  resetOptions,
  loadMore,
  paginatedData,
  handleSearchChange,
  resetAll: () => {
    resetOptions();
    handleSearchChange("", false);
  },
  resetPaginatedOptionsMeta
});
</script>
